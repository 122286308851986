import './App.css';

function App() {
    return <>
        <header id="header">
            Lorem ipsum dolor sit amet, consectetur adipisicing elit.
        </header>
        <main id="content">
            <h1>My Portfolio content.</h1>
        </main>
        <footer id="footer">
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. A, architecto cum dolorem doloremque eveniet
        </footer>
    </>;
}

export default App;
